/* Custom Fonts*/
@font-face {
  font-family: Montserrat;
  src: url('./assets/font/Roboto-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url('./assets/font/Roboto-Regular.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url('./assets/font/Roboto-Light.ttf');
  font-weight: 400;
}

/* Default CSS*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  overflow-x: hidden;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #fff !important;
  color: #FFF !important;
  font-family: 'Montserrat', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  color: #000000;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
  color: #000000;
}

/* theme */
#light {
  background-color: #fff;
}

#light h1,
#light h2,
#light h3,
#light h4,
#light h5 {
  color: #1b1b1d;
}

#light p,
#light a,
#light li,
#light button,
#light ul,
#light span {
  color: #1b1b1d;
}

#dark {
  background-color: #1b1b1d;
}

#dark h1,
#dark h2,
#dark h3,
#dark h4,
#dark h5 {
  color: #907e5d;
}

#dark p,
#dark a,
#dark li,
#dark button,
#dark ul,
#dark span {
  color: #907e5d;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

body h2 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 3px;
  text-align: center;
}

body h3 {
  font-size: 2em;
  font-weight: 500;
}

body p {
  font-size: 1.1em;
  font-weight: 500;
}

@media(max-width: 860px) {
  body h2 {
    font-size: 47px;
    font-weight: 650;
  }

  body h3 {
    font-size: 1.7em;
  }

  body p {
    font-size: 1em;
    font-weight: 400;
  }
}

@media(max-width: 480px) {
  body h2 {
    font-size: 45px;
    font-weight: 600;
  }

  body h3 {
    font-size: 1.5em;
    font-weight: 450;
  }

  body p {
    font-size: 0.9em;
    font-weight: 450;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/* NavBar CSS*/

nav.navbar {
  padding: 5px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  background-color: #000000;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #907e5d;
}

nav.navbar a.navbar-brand {
  width: 150px;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 1px;
  margin: 10px 20px 10px;
  font-size: 18px;
  opacity: 0.5;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: table;
  margin: 0 auto;
  align-items: center;
}

.social-icon {
  display: inline-block;
}

.social-icon a {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto 5px auto;
  line-height: 1;
}

.social-icon a::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  height: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text .cv-text {
  text-decoration: none;
}

.navbar-text h6 {
  margin: 10px;
  font-size: 16px;
  transition: .5s ease;
  color: #fff;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/* theme switch */
.switch {
  font-size: 15px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
}

.switch:hover {
  filter: drop-shadow(0 0 0.25rem #dfba75);
  transition: .5s;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  --background: #0C2D48;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  transition: .5s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 50%;
  left: 10%;
  bottom: 15%;
  box-shadow: inset 8px -4px 0px 0px #DBE8D8;
  background: var(--background);
  transition: .5s;
}

input:checked+.slider {
  background-color: #8b8985;
}

input:checked+.slider:before {
  transform: translateX(100%);
  box-shadow: inset 15px -4px 0px 15px #FDFD96;
}

@media (max-width: 1160px) {
  nav.navbar a.navbar-brand {
    width: 100px;
  }

  nav.navbar .navbar-nav .nav-link.navbar-link {
    margin: 0;
    font-size: 15px;
  }

  .social-icon a {
    margin: 0;
  }

  .social-icon a::before {
    width: 35px;
    height: 35px;
  }

  .social-icon a img {
    width: 35%;
    height: 35%;
  }

  .navbar-text h6 {
    margin: 5px;
    font-size: 15px;
  }

  .switch {
    font-size: 12px;
  }
}

@media (max-width: 860px) {
  nav.navbar a.navbar-brand {
    width: 80px;
  }

  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-size: 13px;
  }

  .social-icon a::before {
    width: 30px;
    height: 30px;
  }

  .social-icon a img {
    width: 30%;
    height: 30%;
  }

  .navbar-text h6 {
    margin: 4px;
    font-size: 13px;
  }

  .switch {
    font-size: 11px;
  }
}

/* Banner CSS */
.banner {
  margin-top: 0;
  padding: 200px 0 150px 0;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 5px;
  color: #000000;
  /* -webkit-text-stroke: 2px #1b1b1b; */
  padding: 10px 10px;
  font-size: 75px;
  display: inline-block;
  /* background: url(./assets/img/textOver.png);
  -webkit-background-clip: text;
  background-position: 0 0;
  animation: loop 5s linear infinite; */
}

/* @keyframes loop {
  100%{
    background-position: 200px 0;
  }
} */
.banner h1 {
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 1;
  display: block;
}

.banner img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
  /* animation: updown 5s linear infinite; */
}

.banner img:hover {
  box-shadow: 7px 5px 56px -14px #907e5d;
  transition: .8s;
}

/* @keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
} */
.txt-rotate>.wrap {
  border-right: 0.06em solid #907e5d;
}

@media (max-width: 870px) {
  .banner .tagline {
    font-weight: 600;
    font-size: 50px;
  }
}

/* Skills CSS */

.skill {
  padding: 80px 0;
  position: relative;
}

.skill-bx {
  text-align: center;
  padding: 50px 50px;
  margin-top: 50px;
}

.skill-slider {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}

.skill-slider .item {
  object-fit: contain;
  position: relative;
  width: 100%;
  margin: 0 auto 0 auto;
}

.skill-slider .item img {
  height: 50%;
  width: 50%;
  object-fit: contain;
  transition: 0.5s ease;
}

.skill-slider .item .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skill-slider .item:hover .overlay {
  opacity: 1;
  transform: scale(1.3);
  transition: 0.5s ease;
}

.skill-slider .item:hover img {
  opacity: 0.1;
  transform: scale(1.2);
  transition: 0.5s ease;
}

.skill-slider .item .txt {
  color: #907e5d;
  font-size: 30px;
  font-style: bold;
  text-decoration: none;
}

.skill-slider .item .overlay a {
  color: #907e5d;
}

.skill-slider .item .overlay a.second {
  color: #907e5d;
  margin-top: 20px;
}

/* Experience CSS */

.experience {
  position: relative;
  padding: 80px 0;
}

/* Projects CSS */

.project {
  padding: 80px 0;
  position: relative;
}

/* .project h5 {
  font-size: 16px;
  color: #888;
  display: flex;
  margin-top: 20px;
} */

.projcard {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
  cursor: default;
  transition: 0.5s ease;
}

.projcard:hover {
  transform: translate(0px, -3px);
  transition: 0.5s ease;
}

.projcard::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.projcard:hover .projcard-bar {
  width: 50%;
  transition: 0.5s ease;
}

.projcard-textbox {
  position: absolute;
}

.projcard-bar {
  left: 0;
  width: 50px;
  height: 5px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #424242;
  transition: width 0.5s ease;
}

.projcard-red .projcard-bar {
  background-color: #ff4d00;
}

.projcard-yellow .projcard-bar {
  background-color: #F5AF41;
}

.projcard-usingitems {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.projcard-usingitems a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.5s ease;
}

.projcard-usingitems a:hover {
  width: 120px;
}

.projcard-usingitems a:before {
  position: absolute;
  inset: 0;
  border-radius: 50px;
  opacity: 0;
  transition: 0.5s;
}

.projcard-usingitems a:hover::before {
  opacity: 1;
}

.projcard-usingitems a::after {
  position: absolute;
}

.projcard-usingitems img {
  width: 100%;
  height: 100%;
  transition: transform 0.5s, width 0.5s, height 0.5s;
}

.projcard-usingitems a:hover img {
  transform: scale(0);
  width: 0;
  height: 0;
}

.projcard-usingitems span {
  position: absolute;
}

.projcard-usingitems .itm {
  color: #fff;
  transform: scale(0);
  transition: 0.5s;
  transition-delay: 0s;
}

.projcard-usingitems a:hover .itm {
  transform: scale(1);
  transition-delay: 0.25s;
}

.projcard-git {
  margin-top: 20px;
}

.projcard-git a {
  width: 100px;
  height: 50px;
  font-size: 1.1em;
  font-weight: 700px;
  text-decoration: none;
  transition: 0.5s ease;
}

.projcard-git a:hover {
  letter-spacing: 10px;
  cursor: pointer;
  transition: 0.5s ease;
}

@media(max-width: 440px) {
  .projcard-git a {
    font-size: 1em;
    font-weight: 500px;
  }
}

.projct-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
  transition: 0.5s ease;
}

.projct-img img {
  width: 240px;
  height: 500px;
  margin: 5px;
  border-radius: 10px;
  position: relative;
  transition: 0.5s ease;
}

.projct-img img:nth-child(2) {
  width: 192px;
  height: 400px;
}

.projct-img img:hover {
  transform: scale(1.3);
  transition: 0.5s ease;
}

.projct-img img:nth-child(2):hover {
  transform: scale(1.5);
  transition: 0.5s ease;
}

.projct-img img::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.projct-img img:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Footer CSS */
.footer {
  /* border-top: 1px solid #907e5d; */
  padding: 20px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer img {
  width: 30%;
  margin-right: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}