::selection{
  color: #fff;
  background: #ff7979;
}
.timeline{
  max-width: 1080px;
  margin: 50px auto;
  padding: 0 20px;
  position: relative;
}
.timeline .center-line{
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

/* Theme */
#light .timeline .center-line,
#light .row section .circle,
#light .row-1 section::before,
#light .row-2 section::before {
    background: #1b1b1d;
  }

#dark .timeline .center-line,
#dark .row section .circle,
#dark .row-1 section::before,
#dark .row-2 section::before {
  background: #907e5d;
}

.timeline .row{
  display: flex;
  margin: 20px 0;
  position: relative;
  white-space: pre-line;
}
.timeline .row-1{
  justify-content: flex-start;
}
.timeline .row-2{
  justify-content: flex-end;
}
.timeline .row section{
  background: transparent;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
}
.row section::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 4px;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
}
.row-1 section::before {
  right: -40px;
}
.row-2 section::before {
  left: -40px;
}
.row-1 section .main{
  text-align: end;
}
.row-2 section .main{
  text-align: start;
}
.row-1 section .content{
  text-align: end;
}
.row-2 section .content{
  text-align: start;
}
.row section .content .duration{
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
  letter-spacing: 3px;
}
@media(max-width: 790px){
  .timeline .center-line{
    left: 40px;
  }
  .timeline .row{
    margin: 30px 0 3px 60px;
  }
  .timeline .row section{
    width: 100%;
  }
  .row-1 section .main{
    text-align: start;
  }
  .row-1 section .content{
    text-align: start;
  }
  .row-1 section::before {
    left: -40px;
  }
}
@media(max-width: 440px){
  .timeline .center-line {
    left: 10px;
    width: 3.2px;
  }
  .row section::before {
    left: -10px;
    width: 20px;
    height: 2.6px;
  }
  .timeline .row{
    margin: 10px 0;
  }
}
